<template>
  <CFooter :fixed="false">
    <div style="font-size: 12px;">
      <a :href="url" target="_blank">Traditional Force</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}} {{slogan}}</span>
    </div>
    <div class="mfs-auto">
      <span v-for="(item, index) in menu">
        <a :href="item.target" target="_blank">{{item.label}}</a><span v-if="index+1 != menu.length"> | </span> 
      </span>
    </div>
  </CFooter>
</template>

<script>
import store from '../store'
import ws from '../services/general';

export default {
  name: 'TheFooter',
  data() {
      return {
        name: '',
        url: '/',
        slogan: '',
        menu: []
      }
  },
  mounted: async function() {
      setTimeout(() => {
        store.commit("getParameter", "PROJECT_NAME")
        this.name = store.state.parameter;

        store.commit("getParameter", "PROJECT_URL")
        this.url = store.state.parameter;

        store.commit("getParameter", "PROJECT_SLOGAN")
        this.slogan = store.state.parameter;
      }, 2000);

      let response = await ws.getMenu("footer"); 

      if(response.type == "success"){
        this.menu = response.data;
      }
  }, 
}
</script>
